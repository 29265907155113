<template>
  <div class="novel-list">
    <ul v-if="showTag">
      <li>
        <div>
          <strong>类别 </strong>
          <div class="spanTag">
            <el-radio-group v-model="categoriesChe" size="mini">
              <el-radio-button v-for="(item, index) in categories.model" :key="index" :label="item.name"/>
            </el-radio-group>
          </div>
        </div>
      </li>
      <li>
        <div>
          <strong>标签 </strong>
          <div class="spanTag">
            <el-radio-group v-model="tagChe" size="mini">
              <el-radio-button v-for="(item, index) in tag.model" :key="index" :label="item.name"/>
            </el-radio-group>
          </div>
        </div>
      </li>
      <li>
        <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
          <el-button @click="search" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </li>
    </ul>
    <el-divider><i class="el-icon-mobile-phone" @click="showTag = !showTag"></i>
      <el-divider direction="vertical"></el-divider>
      <i class="el-icon-tickets" @click="recordsShow = true"></i></el-divider>
    <div>
      <ul>
        <li v-for="(item, index) in novels.model.data" :key="index">
          <div @click="jump(item.id)">
            <NovelCard :host="host" :novel="item"></NovelCard>
          </div>
        </li>
      </ul>
    </div>

    <el-drawer
        title="历史记录"
        :visible.sync="recordsShow"
        size="100%"
        direction="btt"
    >
      <el-pagination style="text-align: center"
                     small
                     layout="prev, pager, next"
                     :size=this.pageSize
                     @current-change="getNovelRecordList"
                     :total="novelsRecords.total">
      </el-pagination>
      <ul v-for="(item, index) in novelsRecords.list" :key="index">
        <li @click="jump(item.nrNvId)">
          <NovelCard
              :novel="{'title': item.nrTitle, 'coverUrl': item.nrImg == null ? item.nrImg : require('@/assets/image/20220616214841_396f9.jpeg') }"></NovelCard>
        </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
import NovelCard from '@/components/list/NovelCard.vue';

import Nas from '@/request/Novel.js'

export default {
  name: "novel_index",
  components: {
    NovelCard
  },
  data() {
    return {
      ask: new Nas(),
      showTag: false,
      recordsShow: false,
      host: "https://www.uaa003.com",
      tag: {model: []},
      categories: {model: []},
      novels: {model: {data: []}},
      categoriesChe: '全部类别',
      tagChe: '全部标签',
      input3: '',
      novelsRecords: {"list": [], "total": 0},
      pageSize: 10
    };
  },
  mounted() {
    this.getCategories();
    this.getTags()
    this.getNovel()
    this.getNovelRecordList(1)
  },
  methods: {
    search() {
      this.getNovel()
    },
    jump(id) {
      this.$router.push({
        name: 'navel_see',
        query: {
          id: id
        }
      })
    },
    getTags() {
      this.ask.getTags().then(res => {
        this.tag = res
        this.tag.model.unshift({
          "id": "0",
          "name": "全部标签"
        })
      })
    },
    getCategories() {
      this.ask.getCategories().then(res => {
        this.categories = res
        this.categories.model.unshift({
          "id": "0",
          "name": "全部类别"
        })
      })
    },
    getNovelRecordList(pageNumber) {
      this.ask.getNovelRecordList({"pageNum": pageNumber, "pageSize": this.pageSize}).then(res => {
        this.novelsRecords = res
      })
    },
    getNovel() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let param = {
        category: this.categoriesChe == '全部类别' ? '' : this.categoriesChe,
        excludeTags: "",
        finished: "",
        includeTags: this.tagChe == '全部标签' ? '' : this.tagChe,
        keyword: this.input3,
        orderType: 2,
        page: 1,
        searchType: 1,
        size: 40,
        source: "",
        space: "",
      }
      this.ask.getNovel(param).then(res => {
        this.novels = res
        loading.close()
      }).catch(error => {
        loading.close()
      });
    }
  }
}
</script>

<style scoped>
.novel-list {
  margin-top: 10px;
}

.spanTag {
  margin: 3px 5px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul > li {
  margin-top: 10px;
}
</style>